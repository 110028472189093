<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">微环境气象监测</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="监测时间" prop="JCSJ">
                    <el-date-picker
                        v-model="formdata.dataDic.JCSJ"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime"
                        :disabled="!isEdit"
                        placeholder="请选择时间"
                    ></el-date-picker>
                </el-form-item>

                <el-form-item label="设备名称" prop="SBMC">
                    <el-input
                        v-model="formdata.dataDic.SBMC"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="温度" prop="WD">
                    <el-input
                        v-model="formdata.dataDic.WD"
                        placeholder="请输入温度"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">℃</template>
                    </el-input>
                </el-form-item>

                <el-form-item label="湿度" prop="SD">
                    <el-input
                        v-model="formdata.dataDic.SD"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">%</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="风向" prop="FX">
                    <el-input
                        v-model="formdata.dataDic.FX"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                        ><template slot="append">度</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="风速" prop="FS">
                    <el-input
                        v-model="formdata.dataDic.FS"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">m/s</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="降雨量" prop="YL">
                    <el-input
                        v-model="formdata.dataDic.YL"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">mm</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="气压" prop="QY">
                    <el-input
                        v-model="formdata.dataDic.QY"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">hPa</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="太阳辐射" prop="TYFS">
                    <el-input
                        v-model="formdata.dataDic.TYFS"
                        placeholder="请输入"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">w/㎡</template>
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../../0_com_js/info_mixin.js";
export default {
    name: "zr_dqzl",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "8020103",
                dataDic: {
                    JCSJ: "",
                    SBMC: "",
                    WD: "",
                    SD: "",
                    FX: "",
                    FS: "",
                    YL: "",
                    QY: "",
                    TYFS: "",
                },
            },
            SGLXList: [],
        };
    },
    mounted() {
        // this.GetEnum();
    },
    methods: {
        ...mapActions(["getAllEnumInfobytableID"]),
        async GetEnum() {
            // let res = await this.getAllEnumInfobytableID({
            //     bid: "1303",
            // });
            // let enumList = res || [];
            // enumList.forEach((v) => {
            //     if (v.COLUMNID === "JB") {
            //         this.SGLXList = v.dataItems;
            //     }
            // });
        },
    },
};
</script>

<style>
.yearBox .yearItem {
    width: 48% !important;
}
.yearBox .separate {
    padding: 0 10px;
}
</style>
